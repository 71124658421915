
export const CustomizationOfferMixin = {

  computed: {
    getActiveTab() {
      return this._.find(this.navTabs, {active: true})
    }
  },

  methods: {
    save(edit = false) {
      let saveType = 'createCustomizationOffer'

      if(!this.CO.validationData()) return

      let data = this.CO.prepareData()

      if(edit) {
        saveType = 'updateCustomizationOffer'
        data = {
          data: data,
          id: this.CO.data.Order.getId()
        }
      }

      this.$store.dispatch(saveType, data).then((response) => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsPackagingTypes + `?type=${this.CO.getType()}`)

            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    }
  },

}