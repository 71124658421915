<template>
  <CustomizationOfferCreateAdmin
      :CO="CO"
      :orderData="orderData"
      @save="save()"
  />
</template>

<script>
import {CustomizationOffer} from "@/components/modules/PackagingTypesModule/models/CustomizationOffer";
import {CustomizationOfferMixin} from "@/mixins/customizationOfferMixins/CustomizationOfferMixin";
import CustomizationOfferCreateAdmin
  from "@/components/modules/PackagingTypesModule/components/CustomizationOfferCreate/CustomizationOfferCreateAdmin/CustomizationOfferCreateAdmin";

export default {
  name: "CustomizationOfferCreate",
  components: {CustomizationOfferCreateAdmin},

  mixins: [CustomizationOfferMixin],

  data() {
    return {
      CO: new CustomizationOffer(),
      orderData: null,
    }
  },

  mounted() {
    this.CO.data.type = this.CO.data.types[this.$route.params.type].name

    this.$store.dispatch('getCreateCustomizationOffer').then(response => {
      this.orderData = this.getRespData(response)
    })
  },

  methods: {

  },

}

</script>

<style scoped>

</style>