<template>
  <form class="order-create__section">
    <div class="order-create__row custom-row order-wrap">
      <div class="custom-col custom-col--66 custom-col--md-100">
        <CustomizationOffer
            :CO="CO"
            :orderData="orderData"
        />
      </div>
    </div>
  </form>
</template>

<script>
import CustomizationOffer
  from "@/components/modules/PackagingTypesModule/components/chunks/CustomizationOffer/CustomizationOffer";
export default {
  name: "CustomizationStepOne",
  components: {CustomizationOffer},

  props: {
    CO: Object,
    orderData: Object,
  }

}
</script>

<style scoped>

</style>