<template>
  <CustomizationOfferEditAdmin
      :CO="CO"
      :orderData="orderData"
      @save="save(true)"
  />
</template>

<script>
import CustomizationOfferEditAdmin
  from "@/components/modules/PackagingTypesModule/components/CustomizationOfferEdit/CustomizationOfferEditAdmin/CustomizationOfferEditAdmin";
import {CustomizationOffer} from "@/components/modules/PackagingTypesModule/models/CustomizationOffer";
import {CustomizationOfferMixin} from "@/mixins/customizationOfferMixins/CustomizationOfferMixin";

export default {
  name: "CustomizationOfferEdit",
  components: {CustomizationOfferEditAdmin},

  mixins: [CustomizationOfferMixin],

  data() {
    return {
      CO: new CustomizationOffer(),
      orderData: null,
    }
  },

  mounted() {
    this.CO.data.type = this.CO.data.types[this.$route.params.type].name
    this.CO.data.Order.setId(this.$route.params.id)

    this.$store.dispatch('getCreateCustomizationOffer').then(response => {
      this.orderData = this.getRespData(response)
    })

    this.initCustomizationOffer()
  },

  methods: {
    initCustomizationOffer() {
      this.$store.dispatch('getCustomizationOffer', this.CO.data.Order.getId()).then(response => {
        let responseData = this.getRespData(response)

        this.CO.setItem(responseData)
      })
    }
  },

}

</script>

<style scoped>

</style>