// import {CalculatorEngraving} from "@/components/modules/CalculatorEngravingModule/models/CalculatorEngraving";
// import {Embroidery} from "@/components/modules/CalculatorEngravingModule/models/Embroidery";
// import {TShirtPrinting} from "@/components/modules/CalculatorEngravingModule/models/TShirtPrinting";
import {Order} from "@/components/globalModels/Order";
import {checkValid} from "@/components/globalModels/functions/ModelsValidate";


export function CustomizationOffer() {

  this.checkValid = checkValid

  this.data = {
    type: null,
    types: {
      engraving: {
        name: 'engraving'
      },
      embroidery: {
        name: 'embroidery'
      },
      dtg_printing: {
        name: 'dtg_printing'
      },
      leather_stamping: {
        name: 'leather_stamping'
      },
      photo_printing: {
        name: 'photo_printing'
      },
      photo_printing_size: {
        name: 'photo_printing_size'
      },
      uv_printing: {
        name: 'uv_printing'
      },
    },

    name: '',
    price: '',
    barcode: '',
    variations: [],
    enable: false,
    product: null,
    Order: new Order(),

    // Engraving: new CalculatorEngraving(),
    // Embroidery: new Embroidery(),
    // TShirtPrinting: new TShirtPrinting(),
  }

  this.validation = {
    name: false,
    price: false,
    barcode: false,
    variations: false,
  }

  this.validationTranslate = {
    name: '',
    price: '',
    barcode: '',
    variations: '',
  }

  this.getName = () => {
    return this.data.name
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getBarcode = () => {
    return this.data.barcode
  }
  this.getVariations = () => {
    return this.data.variations
  }
  this.getEnable = () => {
    return this.data.enable
  }
  this.getType = () => {
    return this.data.type
  }
  this.getProduct = () => {
    return this.data.product
  }

  this.setName = (val) => {
    this.data.name = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setBarcode = (val) => {
    this.data.barcode = val
  }
  this.setVariations = (val) => {
    this.data.variations = val
  }
  this.setEnable = (val) => {
    this.data.enable = val
  }
  this.setType = (val) => {
    this.data.type = val
  }
  this.setProduct = (val) => {
    this.data.product = val
  }

}

CustomizationOffer.prototype.setItem = function (item) {
  this.setName(item.name)
  this.setPrice(item.price)
  this.setBarcode(item.barcode)
  this.setEnable(item.enable === 1)
  this.setVariations(item.d_m_customizing_variants)
  this.setProduct(item.product)
}

CustomizationOffer.prototype.validationData = function () {
  // let valid = true
  // switch (this.data.type) {
  //   case 'engraving' :
  //     valid = this.data.Engraving.dataValidation()
  //     break
  //   case 'embroidery' :
  //     valid = this.data.Embroidery.dataValidation()
  //     break
  //   case 'dtg_printings' :
  //     valid = this.data.TShirtPrinting.dataValidation()
  //     break
  // }

  let validationItems = {
    name: this.getName(),
    price: this.getPrice(),
    barcode: this.getBarcode(),
    variations: this.getVariations(),
  }

  // let isVariations = this.getType() === 'dtg_printings'

  let validationOptions = {
    name: {type: ['empty']},
    price: {type: ['empty']},
    barcode: {type: this.data.type === this.data.types.photo_printing_size.name ? [] : ['empty']},

    // variations: {type: isVariations ? ['empty-array'] : []},
  }

  return this.checkValid(validationItems, validationOptions)
}

CustomizationOffer.prototype.prepareData = function () {

  let data = {
   "name": this.getName(),
   "enable" : this.getEnable() ? 1 : 0,
   "price": this.getPrice(),
   "customization_type": this.getType(),
   "barcode": this.getBarcode(),
   "product_id": this.getProduct()?.id || null,
  }

  if(this.getType() === this.data.types.dtg_printing.name && this.getVariations().length > 0) {
    data["variationIds"] = this.getVariations().map(item => {return item.id})
  }

  // switch (this.data.type) {
  //   case 'engraving' :
  //     data = this.data.Engraving.prepareData()
  //     break
  //   case 'embroidery' :
  //     data = this.data.Embroidery.prepareData()
  //     break
  //   case 'dtg_printings' :
  //     data = this.data.TShirtPrinting.prepareData()
  //     break
  // }

  return data
}