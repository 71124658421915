<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
      >
        <template slot="body">
          <div class="user-info-admin">
            <div class="user-info-admin__inner">
              <div class="user-info-admin__caption"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['packingTypes_Created'])"></div>
                {{$t('packingTypes_Created.localization_value.value')}}
              </div>
              <div class="user-info-admin__large">
                {{ CO.data.Order.data.createdAt | moment("DD MMM, YYYY") }}
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'ex_backTo',
                ])"></div>
                <span class="order-create__head-back">
                  <LinkBack
                      :value="$t('ex_backTo.localization_value.value')"
                      @click.native="goRouterBack()"
                  />
                </span>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['breadcrumbs_customizationOfferEdit'])"></div>
              {{$t('breadcrumbs_customizationOfferEdit.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['packingTypes_PleaseFillOut'])"></div>
              {{$t('packingTypes_PleaseFillOut.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">

          <CustomizationStepOne
              :CO="CO"
              :orderData="orderData"
          />

        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.mainSettingsPackagingTypes"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_edit'])"></div>
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_edit.localization_value.value')"
                  @click.native="$emit('save')"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>
  </div>
</template>

<script>
import CardLeftBlock from "@/components/coreComponents/CardLeftBlock/CardLeftBlock";
import LinkBack from "@/components/UI/buttons/LinkBack/LinkBack";
import CustomizationStepOne from "@/components/modules/PackagingTypesModule/components/components/CustomizationStepOne";
import CardRightBlock from "@/components/coreComponents/CardRightBlock/CardRightBlock";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
export default {
  name: "CustomizationOfferEditAdmin",
  components: {MainButton, CardRightBlock, CustomizationStepOne, LinkBack, CardLeftBlock},

  props: {
    CO: Object,
    orderData: Object,
  }

}
</script>

<style scoped>

</style>