import { render, staticRenderFns } from "./CustomizationOfferCreate.vue?vue&type=template&id=fa3a0b28&scoped=true"
import script from "./CustomizationOfferCreate.vue?vue&type=script&lang=js"
export * from "./CustomizationOfferCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa3a0b28",
  null
  
)

export default component.exports